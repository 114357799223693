









import { Component, Prop, Vue } from "vue-property-decorator";
import { HistoryResponse } from "@/generated/adminapi";
import Cookies from "js-cookie";

@Component
export default class ResultReport extends Vue {
  @Prop({ type: Object, default: null, required: true })
  readonly testerInfo!: HistoryResponse;

  src = "";

  removeTokens(): void {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName, {
        path: "/",
        secure: true,
        domain:
          process.env.VUE_APP_ENV === "prod" ? ".haii.io" : ".haiidev.co.kr",
      });
    });
  }

  async mounted(): Promise<void> {
    const url = this.testerInfo.user.reportUrl;
    if (!url) return;

    // 쿠키에 _at, _aat 값이 있으면 error 발생 -> 호출 전 삭제
    this.removeTokens();
    this.src = process.env.VUE_APP_ADMIN_SERVER_URL + "/" + url;
  }
}
